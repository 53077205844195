<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form layout="inline">
          <a-form-item>
            <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="page.state"
              placeholder="请选择状态"
            >
              <a-select-option value="1">有效</a-select-option>
              <a-select-option value="2">无效</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchList" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button type="default" @click="addAction" icon="plus-circle"
              >新增</a-button
            >
          </a-form-item>
        </a-form>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
      >
        <template slot="state" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="edit"
            @click="() => editAction(record['id'])"
            >编辑</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="snippets"
            @click="() => editcbAction(record['id'])"
            >复制当前方案
          </a-button>
          <a-button
            v-if="record.id != 12"
            type="link"
            size="small"
            icon="delete"
            @click="() => delConfirm(record['id'])"
            >删除</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="minus-circle"
            v-if="record.id != 12 && record['state'] == '1'"
            @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
            >禁用</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="check-circle"
            v-if="record.id != 12 && record['state'] == '2'"
            @click="() => enabledState(record['id'], 1, '你确定要启用吗？')"
            >启用</a-button
          >
        </template>
      </a-table>
    </div>
    <div class="page-cu-pagination">
      <a-pagination
        :show-total="(total, range) => `总数：${total} 条`"
        :page-size.sync="page.pageSize"
        :total="page.totalRow"
        v-model="page.start"
        @change="changePage"
      />
    </div>
    <div class="page-cu-pagination"></div>
    <a-modal
      title="方案管理"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <div style="max-height: 600px; overflow-y: auto">
          <a-form-model-item label="方案名称" prop="name">
            <a-input
              v-model="formModel.name"
              placeholder="请输入方案名称"
            ></a-input>
          </a-form-model-item>
          <div v-for="(item, index) in formModel.planVideosJson" :key="index">
            <a-form-model-item label="工种" prop="work_id">
              <a-select style="width: 75%" v-model="item.work_id">
                <a-select-option
                  v-for="(item, index) in jobList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
              <a-button
                style="margin-left: 10px"
                v-if="index + 1 == formModel.planVideosJson.length"
                @click="addItem"
                type="primary"
                icon="plus"
              />
              <a-button
                style="margin-left: 10px"
                v-if="index !== 0"
                @click="deleteItem(item, index)"
                type="danger"
                icon="delete"
              />
            </a-form-model-item>
            <a-form-model-item label="关联视频">
              <div>
                <a-tag
                  color="cyan"
                  closable
                  v-for="(vitem, cindex) in item.video_list"
                  :key="cindex"
                  @close="closeVidwoLog(cindex, index)"
                >
                  {{ vitem.title }}
                </a-tag>
                <a-button
                  type="primary"
                  size="small"
                  @click="selectVideo(index, item.video_ids)"
                >
                  选择视频
                  <a-icon type="right" />
                </a-button>
              </div>
            </a-form-model-item>
          </div>
          <a-form-model-item label="排序" prop="sort">
            <a-input
              v-model="formModel.sort"
              placeholder="请输入序号"
            ></a-input>
          </a-form-model-item>
          <a-form-item label="方案描述" prop="content">
            <a-textarea
              v-model="formModel.content"
              :rows="2"
              placeholder="请输入方案描述"
            ></a-textarea>
          </a-form-item>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="topicsvisible"
      title="选择视频"
      ok-text="确认"
      width="860px"
      @ok="addTopicsFrom"
    >
      <div style="max-height: 500px; overflow-y: auto; width: 100%">
        <div>
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="topicsPage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="topicsPage.video_category_id"
                placeholder="请选择视频分类"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in topicsTree"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchvList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <!-- <a-alert
          message="要翻页选择视频时请先保存当页已选择的视频"
          type="warning"
          show-icon
        /> -->
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="topicsColumns"
          :data-source="topicsList"
          :pagination="false"
          bordered
          rowKey="id"
        >
          <template slot="video_url" slot-scope="text, record">
            <video
              :src="text"
              controls
              style="width: 90px; height: 60px; border: 1px dashed #d9d9d9"
            ></video>
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
            :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="topicsPage.pageSize"
            :total="topicsPage.totalRow"
            v-model="topicsPage.start"
            @change="topicsPageChange"
          />
        </div>
        <div class="page-cu-pagination"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      selectedRowKeys: [], //多选视频数据
      formModel: {
        id: "",
        name: "",
        sort: "",
        content: "",
        planVideosJson: [
          {
            work_id: "",
            video_ids: "",
            video_list: [],
          },
        ],
      },
      formModelRules: {
        name: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "方案名称",
          dataIndex: "name",
        },
        {
          title: "方案描述",
          dataIndex: "content",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: {
            customRender: "state",
          },
        },
        {
          title: "操作",
          width: 380,
          align: "center",
          scopedSlots: {
            customRender: "operation",
          },
        },
      ],
      topicsColumns: [
        {
          title: "视频名称",
          dataIndex: "title",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 120,
        },
        {
          title: "视频",
          dataIndex: "video_url",
          key: "video_url",
          scopedSlots: {
            customRender: "video_url",
          },
        },
        {
          title: "视频时长",
          dataIndex: "duration",
          key: "duration",
        },

        // {
        //   title: "操作",
        //   fixed: "right",
        //   dataIndex: "operation",
        //   key: "operation",
        //   width: 100,
        //   scopedSlots: { customRender: "operation" },
        // },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        state: undefined,
        key: "",
      },
      topicsPage: {
        start: 1,
        limit: 10,
        pageSize: 0,
        totalRow: 0,
        video_category_id: undefined,
        key: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        title: "title",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: {
        checked: [],
        halfChecked: [],
      },
      jobList: [], // 工种数据
      videoList: [], // 视频数据
      topicsvisible: false,
      topicsTree: [],
      topicsList: [],
      activeAddIndex: 0,
      acVideo_ids: "",
      acVideo_list: [],
      allVideo_list: [],
    };
  },
  mounted() {
    this.getList();
    console.log(this.formModel.planVideosJson.length);
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /*
     * 表单重置
     **/
    resetFormModel() {
      this.formModel = {
        id: "",
        name: "",
        sort: "",
        content: "",
        planVideosJson: [
          {
            work_id: "",
            video_ids: "",
            video_list: [],
          },
        ],
      };
    },
    // 菜单选择
    handlerCheck(n, e) {
      // this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
      console.log(n);
    },
    // 打开添加方案窗
    addAction() {
      this.resetFormModel();
      this.actionVisible = true;
      this.getjobList();
      this.getvideoData();
    },
    addItem() {
      this.formModel.planVideosJson.push({
        work_id: "",
        video_ids: "",
        video_list: [],
      });
    },
    deleteItem(item, index) {
      this.formModel.planVideosJson.splice(index, 1);
      console.log();
    },
    // 编辑方案
    async editAction(id) {
      console.log(id);
      let res = await Api.Detail({
        id: id,
      });
      if (res && res.code == "0") {
        this.formModel = res.target;
        for (let i = 0; i < res.target.planVideosJson.length; i++) {
          this.formModel.planVideosJson[i].video_list = JSON.parse(
            res.target.planVideosJson[i].video_list
          );
        }
        console.log(this.formModel.planVideosJson);
        this.getjobList();
        this.getvideoData();
        this.actionVisible = true;
      } else {
        this.$message.error(res.message);
      }
    },
    // 复制
    async editcbAction(id) {
      console.log(id);
      let res = await Api.Detail({
        id: id,
      });
      if (res && res.code == "0") {
        this.formModel = res.target;
        for (let i = 0; i < res.target.planVideosJson.length; i++) {
          this.formModel.planVideosJson[i].video_list = JSON.parse(
            res.target.planVideosJson[i].video_list
          );
        }
        console.log(this.formModel.planVideosJson);
        this.getjobList();
        this.getvideoData();
        this.actionVisible = true;
      } else {
        this.$message.error(res.message);
      }
      this.formModel.id = "";
    },
    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },
    // 选中项发生变化时的回调
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.acVideo_list = arr;
    },
    // 选择视频确定
    addTopicsFrom() {
      this.formModel.planVideosJson[this.activeAddIndex].video_ids = [];

      if (this.acVideo_list.length != 0) {
        this.acVideo_list.map(item=>{
            this.formModel.planVideosJson[this.activeAddIndex].video_list.push({...item})
        })
      } else {
        return;
      }
      this.formModel.planVideosJson[this.activeAddIndex].video_list.map(
        (item) => {
          this.formModel.planVideosJson[this.activeAddIndex].video_ids.push(
            item.id
          );
        }
      );
      this.formModel.planVideosJson[this.activeAddIndex].video_list = this.uniqueArray(this.formModel.planVideosJson[this.activeAddIndex].video_list,'id')
      console.log(
        this.acVideo_list,
        this.formModel.planVideosJson[this.activeAddIndex].video_list
      );
      this.formModel.planVideosJson[this.activeAddIndex].video_ids =
        this.formModel.planVideosJson[this.activeAddIndex].video_ids.join(",");
      console.log(this.formModel.planVideosJson[this.activeAddIndex]);
      this.topicsvisible = false;
    },
    // json去重

    uniqueArray(data, Name) {
      //data是json对象，Name是根据什么字段去重
      var map = {},
        dest = [];
      for (var i = 0; i < data.length; i++) {
        var ai = data[i];
        if (i == 0) {
          dest.push(ai);
        } else {
          var filterData = dest.filter(function (e) {
            return e[Name] == ai[Name];
          });
          if (filterData.length == 0) {
            dest.push(ai);
          }
        }
      }
      return dest;
    },
    // uniqueArray(array, key) {
    //   let result = [];
    //   result.push(array[0]);
    //   for (var i = 1; i < array.length; i++) {
    //     if (result[0][key] != array[i][key]) {
    //       result.push(array[i]);
    //     }
    //   }
    //   return result;
    // },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    //视频搜索
    searchvList() {
      this.topicsPage.start = 1;
      this.getvideoData();
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({
        id: id,
      });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.DisabledEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    // 列表
    async getList() {
      let res = await Api.List({
        ...this.page,
      });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    // 获取工种列表
    async getjobList() {
      let res = await Api.jobList({
        start: 1,
        state: "1",
        limit: 9999,
        key: "",
      });
      this.jobList = res.page.list;
    },
    // 获取视频列表
    // async getvideoList() {
    //   let res = await Api.videoList({...this.topicsPage});
    //   this.videoList = res.page.list;
    // },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    closeVidwoLog(cindex, index) {
      let arr = this.formModel.planVideosJson[index].video_ids.split(",");
      arr.splice(cindex, 1);
      this.formModel.planVideosJson[index].video_ids = arr.join(",");
      this.formModel.planVideosJson[index].video_list.splice(cindex, 1);
      console.log(this.formModel.planVideosJson[index]);
    },
    /**
     * 选中视频分类树节点
     */
    selectTopicsTree(key, event) {},
    /**
     * 获取视频分类
     */
    async getTopicsTreeList() {
      const res = await Api.GetTopicsTreeList();
      this.topicsTree = res.treeList;
    },
    // 选择视频弹框
    selectVideo(i, video_ids) {
      this.activeAddIndex = i;
      this.selectedRowKeys =
        video_ids != "" ? video_ids.split(",").map(parseFloat) : [];
      console.log(this.selectedRowKeys);
      this.topicsvisible = true;
      this.getTopicsTreeList();
      this.getvideoData();
    },
    async getvideoData() {
      const res = await Api.videoList({ ...this.topicsPage });
      this.topicsList = res.page.list;
      this.topicsPage.totalRow = res.page.totalRow;
      this.topicsPage.pageSize = res.page.pageSize;
    },
    /**
     * 视频分页操作
     */
    topicsPageChange(e) {
      this.topicsPage.start = e;
      this.getvideoData();
    },
  },
};
</script>

<style scoped>
.addTopics {
  width: 800px;
  display: flex;
}
</style>
