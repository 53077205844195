import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/plan/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/plan/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/plan/update',
        method: 'POST',
        data
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/plan/delete',
        method: 'GET',
        params
    });
}

/**
 * 详情
 */
 export function Detail(params) {
    return request({
        url: '/admin/plan/detail',
        method: 'GET',
        params
    });
}




/**
 * 启用、禁用
 */
 export function DisabledEnabled(params) {
    return request({
        url: '/admin/plan/changeIndex',
        method: 'GET',
        params
    });
}

// 工种列表
export function jobList(params) {
    return request({
        url: '/admin/work/page',
        method: 'GET',
        params
    });
}
/**
 * 获取视频分类树结构
 */
 export function GetTopicsTreeList() {
    return request({
        url: '/admin/videoCategory/getTreeList',
        method: 'GET',
    });
}
// 视频列表
export function videoList(params) {
    return request({
        url: '/admin/video/page',
        method: 'GET',
        params
    });
}